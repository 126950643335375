import { ActionContext } from 'vuex';

import $http from '@/plugins/axios';

import { IRootState } from '@/interfaces/RootState';
import {
  INoteQueryRequest, INoteQueryResponse,
  INoteRequest, INotesRequest, INotesResponse, INoteState,
} from '@/interfaces/Note';

export default {
  namespaced: true,

  state: {
    notesResponse: {
      success: false,
      notes: {
        dealer_id: 1,
        dimension_notes: [],
      },
    },

    noteQueryResponse: {
      success: false,
      dataset_name: '',
      fields: [],
      rows: [],
    },
  },

  getters: {
    getNotesResponse: (state: INoteState): INotesResponse => state.notesResponse,
    getNoteQueryResponse: (state: INoteState): INoteQueryResponse => state.noteQueryResponse,
  },

  mutations: {
    SET_NOTES(state: INoteState, payload: INotesResponse): void {
      state.notesResponse = payload;
    },

    SET_NOTE_QUERY_RESPONSE(state: INoteState, payload: INoteQueryResponse): void {
      state.noteQueryResponse = payload;
    },
  },

  actions: {
    /**
     *
     *
     * @param {ActionContext<INoteState, IRootState>} { commit }
     * @param {INotesRequest} payload
     * @return {*}  {Promise<void>}
     */
    async fetchNotes(
      { commit }: ActionContext<INoteState, IRootState>,
      payload: INotesRequest,
    ): Promise<void> {
      try {
        const response = await $http.Api({
          method: 'POST',
          url: '/notes/list',
          data: payload,
        });

        commit('SET_NOTES', response.data);
      } catch (error) {
        throw error.response;
      }
    },

    /**
     *
     *
     * @param {ActionContext<INoteState, IRootState>} { commit }
     * @param {INoteQueryRequest} payload
     * @return {*}  {Promise<void>}
     */
    async queryNote(
      { commit }: ActionContext<INoteState, IRootState>,
      payload: INoteQueryRequest,
    ): Promise<void> {
      try {
        const response = await $http.Api({
          method: 'GET',
          url: `/viewdata/notesData/${payload.viewName}/${payload.componentName}/${payload.note}`,
        });

        commit('SET_NOTE_QUERY_RESPONSE', response.data);
      } catch (error) {
        throw error.response;
      }
    },

    /**
     *
     *
     * @param {ActionContext<INoteState, IRootState>} { commit }
     * @param {INoteRequest} payload
     * @return {*}  {Promise<void>}
     */
    async createNote(
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      { commit }: ActionContext<INoteState, IRootState>,
      payload: INoteRequest,
    ): Promise<void> {
      try {
        await $http.Api({
          method: 'POST',
          url: '/notes/add',
          data: payload,
        });
      } catch (error) {
        throw error.response;
      }
    },

  },
};
