import fileDownload from 'js-file-download';

import { ActionContext } from 'vuex';

import { IDocument, IDocumentState } from '@/interfaces/Document';
import { IRootState } from '@/interfaces/RootState';

import $http from '@/plugins/axios';

export default {
  namespaced: true,

  state: {
    documents: [],
  },

  getters: {
    getDocuments: (state: IDocumentState): IDocument[] => state.documents,
  },

  mutations: {
    SET_DOCUMENTS(state: IDocumentState, payload: IDocument[]): void {
      state.documents = payload;
    },
  },

  actions: {
    async fetchDocuments(
      { commit }: ActionContext<IDocumentState, IRootState>,
      payload: string,
    ): Promise<void> {
      try {
        const response = await $http.MxC({
          method: 'GET',
          url: `/documents?customerNo=${payload}`,
        });

        commit('SET_DOCUMENTS', response.data?.data);
      } catch (error) {
        throw error.response;
      }
    },

    async createDocument(
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      { commit }: ActionContext<IDocumentState, IRootState>,
      payload: { email: string, customerNo: string, document: string, fileName: string },
    ): Promise<void> {
      try {
        await $http.MxC({
          headers: {
            'Content-Type': 'text/plain',
          },
          method: 'POST',
          url: `/documents?customerNo=${payload.customerNo}&email=${payload.email}&fileName=${payload.fileName}`,
          data: payload.document,
        });
      } catch (error) {
        throw error.response;
      }
    },

    async deleteDocument(
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      { commit }: ActionContext<IDocumentState, IRootState>,
      payload: number,
    ): Promise<void> {
      try {
        await $http.MxC({
          method: 'DELETE',
          url: `/documents/${payload}`,
        });
      } catch (error) {
        throw error.response;
      }
    },

    async downloadDocument(
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      { commit }: ActionContext<IDocumentState, IRootState>,
      payload: { documentId: number, fileName: string },
    ): Promise<void> {
      try {
        const response = await $http.MxC({
          method: 'GET',
          url: `/documents/${payload.documentId}/download`,
          responseType: 'blob',
        });
        fileDownload(response.data, payload.fileName);
      } catch (error) {
        throw error.response;
      }
    },
  },
};
