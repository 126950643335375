import axios, { AxiosRequestConfig } from 'axios';
import { ElNotification } from 'element-plus';

import router from '@/router';

import getConfig from '@/plugins/config';
import { getTokenPopup } from './msal';

const VUE_APP_API_BASE_URL = getConfig('VUE_APP_API_BASE_URL');

const VUE_APP_MXC_API_BASE_URL = getConfig('VUE_APP_MXC_API_BASE_URL');
const VUE_APP_MXC_BASIC_TOKEN = getConfig('VUE_APP_MXC_BASIC_TOKEN');

const $http = {
  Api: axios.create({
    baseURL: VUE_APP_API_BASE_URL,
    headers: {
      'Access-Control-Allow-Origin': '*',
    },
  }),

  MxC: axios.create({
    baseURL: VUE_APP_MXC_API_BASE_URL,
    headers: {
      Authorization: `Basic ${VUE_APP_MXC_BASIC_TOKEN}`,
    },
  }),
};

const accessToken = () => localStorage.getItem('access_token');

const authenticationInterceptor = (config: AxiosRequestConfig) => {
  // eslint-disable-next-line no-param-reassign
  config.headers.Authorization = `Bearer ${accessToken()}`;
  return config;
};

$http.Api.interceptors.request.use(authenticationInterceptor);
$http.Api.interceptors.response.use((response) => response, async (error) => {
  if (error.response.status === 401) {
    const response = await getTokenPopup();
    localStorage.setItem('access_token', response?.accessToken || '');
    localStorage.setItem('username', response?.account?.name || '');
    localStorage.setItem('email', response?.account?.username || '');
  } else if (error.response.status === 403) {
    router.push('/forbidden');
  } else if (error.response.status === 500) {
    ElNotification({
      title: 'Error',
      message: error.response?.data?.error,
      type: 'error',
    });
    router.push('/internal-server-error');
  }
  return Promise.reject(error);
});

export default $http;
