import { ActionContext } from 'vuex';

import $http from '@/plugins/axios';

import { IRootState } from '@/interfaces/RootState';
import {
  ISearchResponse, ISearchState,
} from '@/interfaces/Search';

export default {
  namespaced: true,

  state: {
    searchResults: [],
  },

  getters: {
    getSearchResults: (state: ISearchState): ISearchResponse => state.searchResults,
  },

  mutations: {
    SET_SEARCH_RESULTS(state: ISearchState, payload: ISearchResponse): void {
      state.searchResults = payload;
    },

    CLEAR_SEARCH_RESULTS(state: ISearchState, payload: ISearchResponse): void {
      state.searchResults = payload;
    },
  },

  actions: {
    async fetchSearchResults(
      { commit }: ActionContext<ISearchState, IRootState>,
      payload: { searchDefinition: string; searchValue: string; },
    ): Promise<void> {
      try {
        const response = await $http.Api({
          method: 'GET',
          url: `/search/${payload.searchDefinition}/${payload.searchValue}`,
        });

        commit('SET_SEARCH_RESULTS', response.data);
      } catch (error) {
        throw error.response;
      }
    },
  },
};
