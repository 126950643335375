import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faGripVertical,
  faStickyNote,
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faPaperclip,
  faDownload,
  faExclamationTriangle,
  faExternalLinkAlt,
  faFilter,
  faFileExcel,
  faStar,
  faQuestionCircle,
  faChartLine,
  faPencilAlt,
  faInfoCircle,
  faPlusCircle,
  faTrashAlt,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

library.add(
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  faGripVertical,
  faFileExcel,
  faPaperclip,
  faStickyNote,
  faFilter,
  faDownload,
  faExclamationTriangle,
  faExternalLinkAlt,
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faStar,
  faQuestionCircle,
  faChartLine,
  faPencilAlt,
  faInfoCircle,
  faPlusCircle,
  faTrashAlt,
);

export default FontAwesomeIcon;
