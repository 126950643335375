import { ActionContext } from 'vuex';

import $http from '@/plugins/axios';

import { IRootState } from '@/interfaces/RootState';
import { IFavoritesResponse, IFavoriteState } from '@/interfaces/Favorite';

export default {
  namespaced: true,

  state: {
    favoritesRequest: {
      page_size: 100,
      page_index: 0,
      orders: [],
      filters: [],
    },

    favoritesResponse: {
      success: false,
      dataset_name: '',
      fields: [],
      rows: [],
      pagination: {
        page_size: 100,
        page_index: 0,
        page_count: 0,
        total_rows: 0,
      },
    },
  },

  getters: {
    getFavoritesResponse: (state: IFavoriteState): IFavoritesResponse => state.favoritesResponse,
  },

  mutations: {
    SET_FAVORITES(state: IFavoriteState, payload: IFavoritesResponse): void {
      state.favoritesResponse = payload;
    },

    SET_FAVORITES_ROWS(state: IFavoriteState, payload: []): void {
      state.favoritesResponse.rows = payload;
    },

    SET_FAVORITES_REQUEST_PAGE_INDEX(state: IFavoriteState, payload: number): void {
      state.favoritesRequest.page_index = payload;
    },

    SET_FAVORITES_REQUEST_PAGE_SIZE(state: IFavoriteState, payload: number): void {
      state.favoritesRequest.page_size = payload;
    },
  },

  actions: {
    /**
     *
     *
     * @param {ActionContext<IFavoriteState, IRootState>} { commit }
     * @return {*}  {Promise<void>}
     */
    async fetchFavorites({ commit }: ActionContext<IFavoriteState, IRootState>): Promise<void> {
      try {
        const response = await $http.Api({
          method: 'GET',
          url: '/viewdata/userfavorites/all',
        });

        commit('SET_FAVORITES_ROWS', response.data?.data?.favourites);
      } catch (error) {
        throw error.response;
      }
    },

    /**
     *
     *
     * @param {ActionContext<IFavoriteState, IRootState>} { commit }
     * @param {{ viewName: string; favoriteName: string; }} payload
     * @return {*}  {Promise<void>}
     */
    async createFavorite(
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      { commit }: ActionContext<IFavoriteState, IRootState>,
      payload: { viewName: string; favoriteName: string; },
    ): Promise<void> {
      try {
        await $http.Api({
          method: 'POST',
          url: `/viewdata/favorite/${payload.viewName}/${payload.favoriteName}`,
        });
      } catch (error) {
        throw error.response;
      }
    },

  },
};
