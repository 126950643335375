import { createApp } from 'vue';
import './styles/element/index.scss';
import ElementPlus from 'element-plus';

import App from './App.vue';
import FontAwesomeIcon from './plugins/font-awesome';
import router from './router';
import store from './store';

import '@/assets/css/help.css';

const app = createApp(App);

app
  .use(ElementPlus, { size: 'small' })
  .component('FontAwesomeIcon', FontAwesomeIcon)
  .use(store)
  .use(router)
  .mount('#app');
