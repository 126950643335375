
import { defineComponent, onBeforeMount } from 'vue';

import { ElLoading } from 'element-plus';

import useAuthenticate from '@/uses/useAuthentication';
import useView from '@/uses/useView';

export default defineComponent({
  name: 'App',

  setup() {
    const { authenticate } = useAuthenticate();

    const { fetchViews } = useView();

    onBeforeMount(async (): Promise<void> => {
      const loadingInstance = ElLoading.service({ fullscreen: true });
      try {
        await authenticate();
        await fetchViews();
      } catch (error) {
        console.error(error);
      } finally {
        loadingInstance.close();
      }
    });
  },
});
