import { ActionContext } from 'vuex';

import $http from '@/plugins/axios';

import {
  IColumn,
  IGridRequest,
  IGridResponse,
  IGridState,
} from '@/interfaces/Grid';
import { IRootState } from '@/interfaces/RootState';
import { IFilter } from '@/interfaces/Filter';
import { IOrderRequest } from '@/interfaces/Order';
import { IDrilldownRequest } from '@/interfaces/Drilldown';
import { IDrillThroughRequest } from '@/interfaces/DrillThrough';

export default {
  namespaced: true,

  state: {
    gridRequest: {
      page_size: 100,
      page_index: 0,
      orders: [],
      filters: [],
      drillthrough: null,
    },

    gridResponse: {
      success: false,
      dataset_name: '',
      fields: [],
      rows: [],
      totals: null,
      pagination: {
        page_size: 100,
        page_index: 0,
        page_count: 0,
        total_rows: 0,
      },
    },
  },

  getters: {
    getFilters: (state: IGridState): IFilter[] => state.gridRequest.filters,
    getOrders: (state: IGridState): IOrderRequest[] => state.gridRequest.orders,
    getGridResponse: (state: IGridState): IGridResponse => state.gridResponse,
    getGridRequest: (state: IGridState): IGridRequest => state.gridRequest,
  },

  mutations: {
    SET_GRID_REQUEST(state: IGridState, payload: IGridRequest): void {
      state.gridRequest = payload;
    },

    RESET_GRID_REQUEST(state: IGridState): void {
      state.gridRequest = {
        page_size: 100,
        page_index: 0,
        orders: [],
        filters: [],
        drillthrough: null,
      };
    },

    SET_GRID_REQUEST_FILTERS(state: IGridState, payload: IFilter[]): void {
      state.gridRequest.filters = payload;
    },

    ADD_GRID_REQUEST_FILTER(state: IGridState, payload: IFilter): void {
      state.gridRequest.filters.push(payload);
    },

    REMOVE_GRID_REQUEST_FILTER(state: IGridState, payload: IFilter): void {
      const filterIndex = state.gridRequest.filters.findIndex(
        (filter: IFilter) => filter.id === payload.id
        && filter.filter_text === payload.filter_text,
      );
      state.gridRequest.filters.splice(filterIndex, 1);
    },

    REMOVE_GRID_COLUMN_REQUEST_FILTERS(state: IGridState, payload: string): void {
      state.gridRequest.filters = state.gridRequest.filters.filter(
        (filter) => filter.id !== payload,
      );
    },

    REMOVE_GRID_REQUEST_FILTERS(state: IGridState): void {
      state.gridRequest.filters = [];
    },

    SET_GRID_REQUEST_ORDERS(state: IGridState, payload: IOrderRequest[]): void {
      state.gridRequest.orders = payload;
    },

    SET_GRID_REQUEST_PAGE_SIZE(state: IGridState, payload: number): void {
      state.gridRequest.page_size = payload;
    },

    SET_GRID_REQUEST_PAGE_INDEX(state: IGridState, payload: number): void {
      state.gridRequest.page_index = payload;
    },

    SET_GRID_REQUEST_DRILL_THROUGH(state: IGridState, payload: IDrillThroughRequest | null): void {
      state.gridRequest.drillthrough = payload;
    },

    SET_GRID_REQUEST_DRILL_THROUGH_PARENT_VIEW(state: IGridState, payload: string): void {
      if (state.gridRequest.drillthrough) {
        state.gridRequest.drillthrough.parent_view = payload;
      }
    },

    SET_GRID_REQUEST_DRILL_THROUGH_PARENT_COMPONENT(state: IGridState, payload: string): void {
      if (state.gridRequest.drillthrough) {
        state.gridRequest.drillthrough.parent_component = payload;
      }
    },

    SET_GRID_REQUEST_DRILL_THROUGH_DATA_ROW(
      state: IGridState,
      payload: Record<string, unknown>,
    ): void {
      if (state.gridRequest.drillthrough) {
        state.gridRequest.drillthrough.data_row = payload;
      }
    },

    SET_GRID_RESPONSE(state: IGridState, payload: IGridResponse): void {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const data = payload.rows.map((el, index) => ({ ...el, id: index }));
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      // eslint-disable-next-line no-param-reassign
      payload.rows = data;
      state.gridResponse = payload;
    },
  },

  actions: {
    /**
     *
     *
     * @param {ActionContext<IGridState, IRootState>} { commit }
     * @param {{ viewName: string; gridName: string; gridRequest: IGridRequest; }} payload
     * @return {*}  {Promise<void>}
     */
    async fetchGridData(
      { commit }: ActionContext<IGridState, IRootState>,
      payload: { viewName: string; gridName: string; gridRequest: IGridRequest; },
    ): Promise<void> {
      try {
        const response = await $http.Api({
          method: 'POST',
          url: `/viewdata/grid/${payload.viewName}/${payload.gridName}`,
          data: payload.gridRequest,
        });
        commit('SET_GRID_RESPONSE', response.data?.data);
      } catch (error) {
        throw error.response;
      }
    },

    /**
     *
     *
     * @param {ActionContext<IGridState, IRootState>} { commit }
     * @param {{ viewName: string; gridName: string; gridRequest: IGridRequest; }} payload
     * @return {*}  {Promise<void>}
     */
    async exportGridData(
      { commit }: ActionContext<IGridState, IRootState>,
      payload: { viewName: string; gridName: string; gridRequest: IGridRequest; },
    ): Promise<void> {
      try {
        const response = await $http.Api({
          method: 'POST',
          url: `/viewdata/grid/${payload.viewName}/${payload.gridName}`,
          data: payload.gridRequest,
        });
        commit('SET_GRID_RESPONSE', response.data?.data);
      } catch (error) {
        throw error.response;
      }
    },

    /**
     *
     *
     * @param {ActionContext<IGridState, IRootState>} { commit }
     * @param {{ viewName: string; componentName: string; columns: IColumn[]; }} payload
     * @return {*}  {Promise<void>}
     */
    async updateGridColumns(
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      { commit }: ActionContext<IGridState, IRootState>,
      payload: { viewName: string; componentName: string; columns: IColumn[]; },
    ): Promise<void> {
      try {
        await $http.Api({
          method: 'POST',
          url: `/viewdata/columns/${payload.viewName}/${payload.componentName}`,
          data: payload.columns,
        });
      } catch (error) {
        throw error.response;
      }
    },

    /**
     *
     *
     * @param {ActionContext<IGridState, IRootState>} { commit }
     * @param {{ viewName: string; componentName: string; filters: IFilter[]; }} payload
     * @return {*}  {Promise<void>}
     */
    async updateGridFilters(
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      { commit }: ActionContext<IGridState, IRootState>,
      payload: { viewName: string; componentName: string; filters: IFilter[]; },
    ): Promise<void> {
      try {
        await $http.Api({
          method: 'POST',
          url: `/viewdata/filters/${payload.viewName}/${payload.componentName}`,
          data: { filters: payload.filters },
        });
      } catch (error) {
        throw error.response;
      }
    },

    /**
     *
     *
     * @param {ActionContext<IGridState, IRootState>} { commit }
     * @param {{ viewName: string; componentName: string; orders: IOrder[]; }} payload
     * @return {*}  {Promise<void>}
     */
    async updateGridOrders(
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      { commit }: ActionContext<IGridState, IRootState>,
      payload: { viewName: string; componentName: string; orders: IOrderRequest[]; },
    ): Promise<void> {
      try {
        await $http.Api({
          method: 'POST',
          url: `/viewdata/orders/${payload.viewName}/${payload.componentName}`,
          data: { orders: payload.orders },
        });
      } catch (error) {
        throw error.response;
      }
    },

    /**
     *
     *
     * @param {ActionContext<IGridState, IRootState>} { commit }
     * @param {{
     *  viewName: string;
     *  drilldownName: string;
     *  drilldownRequest: IDrilldownRequest;
     * }} payload
     * @return {*}  {Promise<void>}
     */
    async fetchDrilldownData(
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      { commit }: ActionContext<IGridState, IRootState>,
      payload: { viewName: string; drilldownName: string; drilldownRequest: IDrilldownRequest; },
    ): Promise<void> {
      try {
        const response = await $http.Api({
          method: 'POST',
          url: `/viewdata/drilldown/${payload.viewName}/${payload.drilldownName}`,
          data: payload.drilldownRequest,
        });
        return response.data?.data;
      } catch (error) {
        throw error.response;
      }
    },
  },
};
