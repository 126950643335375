import { ActionContext } from 'vuex';

import $http from '@/plugins/axios';

import { IRootState } from '@/interfaces/RootState';
import {
  IDropdownRequest, IDropdownState,
} from '@/interfaces/Dropdown';

export default {
  namespaced: true,

  state: {
    dropdownResponse: '',
  },

  getters: {},

  mutations: {},

  actions: {
    async fetchDropdownData(
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      { commit }: ActionContext<IDropdownState, IRootState>,
      payload: IDropdownRequest,
    ): Promise<void> {
      try {
        const response = await $http.Api({
          method: 'POST',
          url: '/dataset/data',
          data: payload,
        });

        return response.data?.rows;
      } catch (error) {
        throw error.response;
      }
    },
  },
};
