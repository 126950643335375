import { createStore } from 'vuex';

import { IRootState } from '@/interfaces/RootState';

import Authentication from './modules/authentication';
import Column from './modules/column';
import Customer from './modules/customers';
import Dataset from './modules/dataset';
import Documents from './modules/documents';
import Dropdown from './modules/dropdown';
import Export from './modules/exports';
import Favorite from './modules/favorite';
import Grid from './modules/grid';
import Invoice from './modules/invoices';
import Note from './modules/notes';
import Search from './modules/search';
import View from './modules/view';

export default createStore<IRootState>({
  modules: {
    Authentication,
    Column,
    Customer,
    Dataset,
    Documents,
    Dropdown,
    Export,
    Favorite,
    Grid,
    Invoice,
    Note,
    Search,
    View,
  },

  strict: process.env.NODE_ENV !== 'production',
});
