import { ActionContext } from 'vuex';

import $http from '@/plugins/axios';

import { IRootState } from '@/interfaces/RootState';
import {
  IInvoiceCustomerContactRequest,
  IInvoiceCustomerNoteUpdateRequest,
  IInvoiceNoteRequest,
  IInvoiceNoteStatusRequest,
  IInvoiceRequest,
  IInvoiceResponse,
  IInvoiceState,
  IInvoiceUpdateRequest,
} from '@/interfaces/Invoice';

export default {
  namespaced: true,

  state: {
    invoiceResponse: {
      invoice_id: '',
    },
  },

  getters: {
    getInvoiceResponse: (state: IInvoiceState): IInvoiceResponse => state.invoiceResponse,
  },

  mutations: {
    SET_INVOICE_RESPONSE(state: IInvoiceState, payload: IInvoiceResponse): void {
      state.invoiceResponse = payload;
    },
  },

  actions: {
    async fetchInvoice(
      { commit }: ActionContext<IInvoiceState, IRootState>,
      payload: { viewName: string; gridName: string; gridRequest: IInvoiceRequest; },
    ): Promise<void> {
      try {
        const response = await $http.Api({
          method: 'POST',
          url: `/viewdata/grid/${payload.viewName}/${payload.gridName}`,
          data: payload.gridRequest,
        });
        if (response.data && response.data.data && response.data.data.rows) {
          commit('SET_INVOICE_RESPONSE', response.data.data.rows[0]);
        }
      } catch (error) {
        throw error.response;
      }
    },

    async dynamicInvoiceRequest(
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      { commit }: ActionContext<IInvoiceState, IRootState>,
      payload: {
        url: string;
        method: string;
        data: IInvoiceNoteRequest |
          IInvoiceUpdateRequest|
          IInvoiceCustomerNoteUpdateRequest |
          IInvoiceNoteStatusRequest |
          IInvoiceCustomerContactRequest;
      },
    ): Promise<void> {
      try {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        await $http.Api({
          method: payload.method,
          url: payload.url,
          data: payload.data,
        });
      } catch (error) {
        throw error.response;
      }
    },
  },
};
