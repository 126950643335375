import {
  AuthenticationResult, LogLevel, PublicClientApplication,
  SilentRequest,
} from '@azure/msal-browser';

import getConfig from '@/plugins/config';

const VUE_APP_AUTHENTICATION_BASE_URL = getConfig('VUE_APP_AUTHENTICATION_BASE_URL');
const VUE_APP_AUTHENTICATION_CLIENT_ID = getConfig('VUE_APP_AUTHENTICATION_CLIENT_ID');
const VUE_APP_AUTHENTICATION_SCOPE = getConfig('VUE_APP_AUTHENTICATION_SCOPE');

const clientId: string = VUE_APP_AUTHENTICATION_CLIENT_ID || '';

/** Configuration object to be passes to MSAL instance on creatio,
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
 */
const msalConfig = {
  auth: {
    clientId,
    redirectUri: `${window.location.protocol}//${window.location.host}`,
    authority: `${VUE_APP_AUTHENTICATION_BASE_URL}/organizations`,
  },
  cache: {
    cacheLocation: 'localStorage',
  },
  system: {
    loggerOptions: {
      loggerCallback: (level: unknown, message: string, containsPii: boolean) => {
        if (containsPii) {
          return;
        }
        // eslint-disable-next-line default-case
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
        }
      },
      piiLoggingEnabled: false,
      logLevel: LogLevel.Verbose,
    },
  },
};

/**
 * Scopes you add here will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
 * For more information about OIDC scopes, visit:
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 */
const scope: string = VUE_APP_AUTHENTICATION_SCOPE || '';

/**
 * Scopes you add here will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
 * For more information about OIDC scopes, visit:
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 */
const request = {
  scopes: [scope],
};

const $msal = new PublicClientApplication(msalConfig);

let username = '';

export const selectAccount = (): void => {
  /**
   * See here for more info on account retrieval:
   * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-common/docs/Accounts.md
   */

  const currentAccounts = $msal.getAllAccounts();
  if (currentAccounts.length === 0) {
    return;
  } if (currentAccounts.length > 1) {
    // Add choose account code here
    console.warn('Multiple accounts detected.');
  } else if (currentAccounts.length === 1) {
    username = currentAccounts[0].username;
  }
};

export const handleResponse = (response: AuthenticationResult)
: void => {
  /**
   * To see the full list of response object properties, visit:
   * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/request-response-object.md#response
   */
  if (response !== null) {
    username = response.account?.username || '';
  } else {
    selectAccount();
  }
};

export const signIn = async () : Promise<AuthenticationResult> => {
  /**
   * You can pass a custom request object below.
   * This will override the initial configuration.
   * For more information, visit:
   * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/request-response-object.md#request
   */
  const response = await $msal.loginPopup(request);
  handleResponse(response);
  return response;
};

export const signOut = (): void => {
  /**
   * You can pass a custom request object below.
   * This will override the initial configuration.
   * For more information, visit:
   * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/request-response-object.md#request
   */

  const logoutRequest = {
    account: $msal.getAccountByUsername(username),
    postLogoutRedirectUri: msalConfig.auth.redirectUri,
    mainWindowRedirectUri: msalConfig.auth.redirectUri,
  };

  $msal.logoutPopup(logoutRequest);
};

export const getTokenPopup = async () : Promise<AuthenticationResult | null> => {
  let token: AuthenticationResult;
  const account = $msal.getAccountByUsername(username) || undefined;
  const silentRequest : SilentRequest = { ...request, account };
  try {
    token = await $msal.acquireTokenSilent(silentRequest);
    return token;
  } catch (error) {
    console.warn('silent token acquisition fails. acquiring token using popup');
    console.error(error);
    //  if (error instanceof InteractionRequiredAuthError) {
    // fallback to interaction when silent call fails
    token = await $msal.acquireTokenPopup(request);
    return token;
    // }
  }
};

selectAccount();

export default $msal;
