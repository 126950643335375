import { ActionContext } from 'vuex';

import $http from '@/plugins/axios';

import { IRootState } from '@/interfaces/RootState';
import {
  IViewState, IViewResponse, IViewsResponse, IColumn, IComponentResponse, IAction,
} from '@/interfaces/View';
import { IFilter } from '@/interfaces/Filter';
import { IDrillThroughRequest } from '@/interfaces/DrillThrough';
import { IOrderRequest } from '@/interfaces/Order';

export default {
  namespaced: true,
  state: {
    views: [],

    view: {
      search: '',
      name: '',
      display_props: {
        name: '',
        order: 0,
        show_on_dashboard: false,
      },
      description: '',
      filters: [],
      drilldowns: [],
      components: [],
    },
  },

  getters: {
    getViews: (state: IViewState): IViewsResponse[] => state.views.filter(
      (view) => view.display_props.show_on_dashboard,
    ),

    getView: (state: IViewState): IViewResponse => state.view,

    getCharts: (
      state: IViewState,
    ): IComponentResponse[] | undefined => state.view.components.filter(
      (component) => component.type === 'chart',
    ),

    getDatasetColumns: (state: IViewState): IColumn[] | undefined => {
      const grid = state.view.components.find((component) => component.type === 'grid');
      return grid?.dataset_columns;
    },

    getDatasetMetrics: (state: IViewState): IColumn[] | undefined => {
      const grid = state.view.components.find((component) => component.type === 'grid');
      if (grid && grid.dataset_columns) {
        return grid?.dataset_columns.filter(
          (column) => column.column_type === 'metric',
        );
      }
      return undefined;
    },

    getColumns: (state: IViewState): IColumn[] | undefined => {
      const grid = state.view.components.find((component) => component.type === 'grid');
      if (grid && grid.columns) {
        const columns = [...grid.columns];
        const result = columns
          .sort((a, b) => a.index - b.index)
          .filter((column) => column.ui_visible);
        return result;
      }
      return undefined;
    },

    getHiddenColumns: (state: IViewState): IColumn[] | undefined => {
      const grid = state.view.components.find((component) => component.type === 'grid');
      if (grid && grid.columns) {
        const columns = [...grid.columns];
        const result = columns
          .sort((a, b) => a.index - b.index)
          .filter((column) => !column.ui_visible);
        return result;
      }
      return undefined;
    },

    getDimensions: (state: IViewState): IColumn[] | undefined => {
      const grid = state.view.components.find((component) => component.type === 'grid');
      if (grid && grid.columns) {
        let columns = [...grid.columns];
        columns = columns.sort((a, b) => a.index - b.index).filter(
          (column) => column.column_type === 'dimension',
        );
        return columns;
      }
      return undefined;
    },

    getMetrics: (state: IViewState): IColumn[] | undefined => {
      const grid = state.view.components.find((component) => component.type === 'grid');
      if (grid && grid.columns) {
        let columns = [...grid.columns];
        columns = columns.sort((a, b) => a.index - b.index).filter(
          (column) => column.column_type === 'metric',
        );
        return columns;
      }
      return undefined;
    },

    getFilters: (state: IViewState): IFilter[] => state.view.filters,

    getGridFilters: (state: IViewState): IFilter[] | undefined => {
      const grid = state.view.components.find((component) => component.type === 'grid');
      return grid?.filters;
    },

    getGridOrders: (state: IViewState): IOrderRequest[] | undefined => {
      const grid = state.view.components.find((component) => component.type === 'grid');
      return grid?.orders;
    },

    getGridDrillThrough: (state: IViewState): string | undefined => {
      const grid = state.view.components.find((component) => component.type === 'grid');
      return grid?.drillthrough;
    },

    getDrilldowns: (state: IViewState): IComponentResponse[] => state.view.drilldowns,

    getDataSource: (state: IViewState): string | undefined => {
      const grid = state.view.components.find((component) => component.type === 'grid');
      return grid?.data_source;
    },

    getGridName: (state: IViewState): string | undefined => {
      const grid = state.view.components.find((component) => component.type === 'grid');
      return grid?.name;
    },

    getGridNotesStatus: (state: IViewState): boolean => {
      const grid = state.view.components.find((component) => component.type === 'grid');
      if (grid) {
        return grid.hasnotes;
      }
      return false;
    },

    getActions: (state: IViewState): IAction[] | undefined => {
      const grid = state.view.components.find((component) => component.type === 'grid');
      return grid?.actions;
    },

    getUserSelection: (state: IViewState): string | undefined => {
      const grid = state.view.components.find((component) => component.type === 'grid');
      return grid?.user_selection;
    },
  },

  mutations: {
    SET_VIEWS(state: IViewState, payload: IViewsResponse[]): void {
      const views = payload.sort((a, b) => a.display_props.order - b.display_props.order);
      state.views = views;
    },

    SET_VIEW(state: IViewState, payload: IViewResponse): void {
      state.view = payload;
    },

    ADD_COLUMN(state: IViewState, payload: IColumn): number | undefined {
      const grid = state.view.components.find((component) => component.type === 'grid');
      return grid?.columns?.push(payload);
    },

    ADD_METRIC(state: IViewState, payload: IColumn): void {
      const grid = state.view.components.find((component) => component.type === 'grid');
      if (grid?.columns) {
        grid.columns.push(payload);
      }
    },

    UPDATE_COLUMN(
      state: IViewState,
      payload: {index: number; column: IColumn},
    ): IColumn[] | undefined {
      const grid = state.view.components.find((component) => component.type === 'grid');
      return grid?.columns?.splice(payload.index, 1, payload.column);
    },

    REMOVE_COLUMN(state: IViewState, payload: string): IColumn[] | undefined {
      const grid = state.view.components.find((component) => component.type === 'grid');
      const columnIndex = grid?.columns?.findIndex((column) => column.id === payload.toString());
      if (columnIndex) {
        return grid?.columns?.splice(columnIndex, 1);
      }
      return undefined;
    },

    UPDATE_COLUMNS(state: IViewState, payload: IColumn[]): void {
      const grid = state.view.components.find((component) => component.type === 'grid');
      if (grid && grid.columns) {
        const hiddenColumns = grid.columns.filter((column) => column.ui_visible === false);
        grid.columns = payload.concat(hiddenColumns);
      }
    },

    SET_GRID_FILTERS(state: IViewState, payload: IFilter[]): IFilter[] | undefined {
      const grid = state.view.components.find((component) => component.type === 'grid');
      if (grid?.filters) {
        grid.filters = payload;
      }
      return undefined;
    },

    ADD_GRID_FILTER(state: IViewState, payload: IFilter): void {
      const grid = state.view.components.find((component) => component.type === 'grid');
      if (grid?.filters) {
        grid.filters.push(payload);
      }
    },

    REMOVE_GRID_FILTER(state: IViewState, payload: IFilter): IFilter[] | undefined {
      const grid = state.view.components.find((component) => component.type === 'grid');
      const filterIndex = grid?.filters.findIndex(
        (filter: IFilter) => filter.id === payload.id
        && filter.filter_text === payload.filter_text,
      );
      if (filterIndex) {
        return grid?.filters.splice(filterIndex, 1);
      }
      return undefined;
    },

    REMOVE_GRID_COLUMN_FILTERS(state: IViewState, payload: string): IFilter[] | undefined {
      const grid = state.view.components.find((component) => component.type === 'grid');
      if (grid && grid.filters) {
        grid.filters = grid.filters.filter((filter) => filter.id !== payload);
      }
      return undefined;
    },

    REMOVE_GRID_FILTERS(state: IViewState): IFilter[] | undefined {
      const grid = state.view.components.find((component) => component.type === 'grid');
      if (grid && grid.filters) {
        grid.filters = [];
      }
      return undefined;
    },

  },

  actions: {
    /**
     *
     *
     * @param {ActionContext<IViewState, IRootState>} { commit }
     * @return {*}  {Promise<void>}
     */
    async fetchViews({ commit }: ActionContext<IViewState, IRootState>): Promise<void> {
      try {
        const response = await $http.Api({
          method: 'GET',
          url: '/view/list',
        });

        commit('SET_VIEWS', response.data?.views);
      } catch (error) {
        throw error.response;
      }
    },

    /**
     *
     *
     * @param {ActionContext<IViewState, IRootState>} { commit }
     * @param {string} payload
     * @return {*}  {Promise<void>}
     */
    async fetchView(
      { commit }: ActionContext<IViewState, IRootState>,
      payload: string,
    ): Promise<void> {
      try {
        const response = await $http.Api({
          method: 'GET',
          url: `/viewdata/${payload}`,
        });

        commit('SET_VIEW', response.data);
      } catch (error) {
        throw error.response;
      }
    },

    /**
     *
     *
     * @param {ActionContext<IViewState, IRootState>} { commit }
     * @param {IDrillThroughRequest} payload
     * @return {*}  {Promise<void>}
     */
    async fetchDrillThrough(
      { commit }: ActionContext<IViewState, IRootState>,
      payload: IDrillThroughRequest,
    ): Promise<void> {
      try {
        const response = await $http.Api({
          method: 'POST',
          url: `/viewdata/${payload.parent_view}`,
          data: { drillthrough: payload },
        });

        commit('SET_VIEW', response.data);
      } catch (error) {
        throw error.response;
      }
    },

    /**
     *
     *
     * @param {ActionContext<IViewState, IRootState>} { commit }
     * @param {{viewName: string; favouriteName: string}} payload
     * @return {*}  {Promise<void>}
     */
    async fetchFavorite(
      { commit }: ActionContext<IViewState, IRootState>,
      payload: {viewName: string; favoriteName: string},
    ): Promise<void> {
      try {
        const response = await $http.Api({
          method: 'GET',
          url: `/viewdata/${payload.viewName}?favouriteName=${payload.favoriteName}`,
        });

        commit('SET_VIEW', response.data);
      } catch (error) {
        throw error.response;
      }
    },

    /**
     *
     *
     * @param {ActionContext<IViewState, IRootState>} { commit }
     * @param {{ viewName: string; filters: IFilter[] }} payload
     * @return {*}  {Promise<void>}
     */
    async updateFilters(
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      { commit }: ActionContext<IViewState, IRootState>,
      payload: { viewName: string; filters: IFilter[] },
    ): Promise<void> {
      try {
        await $http.Api({
          method: 'POST',
          url: `/viewdata/filters/${payload.viewName}`,
          data: { filters: payload.filters },
        });
      } catch (error) {
        console.error(error);
      }
    },

    /**
     *
     *
     * @param {ActionContext<IViewState, IRootState>} { commit }
     * @param {string} payload
     * @return {*}  {Promise<void>}
     */
    async resetView(
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      { commit }: ActionContext<IViewState, IRootState>,
      payload: string,
    ): Promise<void> {
      try {
        await $http.Api({
          method: 'POST',
          url: `/viewdata/reset/${payload}`,
        });
      } catch (error) {
        throw error.response;
      }
    },
  },
};
