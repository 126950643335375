import { ActionContext } from 'vuex';

import { IDatasetState } from '@/interfaces/Dataset';
import { IRootState } from '@/interfaces/RootState';

import $http from '@/plugins/axios';

export default {
  namespaced: true,

  state: {
    operations: [],

    functions: [],
  },

  getters: {
    getDatasetOperations: (state: IDatasetState): string[] => state.operations,

    getDatasetFunctions: (state: IDatasetState): string[] => state.functions,
  },

  mutations: {
    SET_DATASET_OPERATIONS(state: IDatasetState, payload: string[]): void {
      state.operations = payload;
    },

    SET_DATASET_FUNCTIONS(state: IDatasetState, payload: string[]): void {
      state.functions = payload;
    },
  },

  actions: {
    /**
     *
     *
     * @param {ActionContext<IDatasetState, IRootState>} { commit }
     * @return {*}  {Promise<void>}
     */
    async fetchDatasetOperations(
      { commit }: ActionContext<IDatasetState, IRootState>,
    ): Promise<void> {
      try {
        const response = await $http.Api({
          method: 'GET',
          url: '/dataset/operations',
        });

        commit('SET_DATASET_OPERATIONS', response.data?.operations);
      } catch (error) {
        throw error.response;
      }
    },

    /**
     *
     *
     * @param {ActionContext<IDatasetState, IRootState>} { commit }
     * @return {*}  {Promise<void>}
     */
    async fetchDatasetFunctions(
      { commit }: ActionContext<IDatasetState, IRootState>,
    ): Promise<void> {
      try {
        const response = await $http.Api({
          method: 'GET',
          url: '/dataset/functions',
        });

        commit('SET_DATASET_FUNCTIONS', response.data?.functions);
      } catch (error) {
        throw error.response;
      }
    },
  },
};
