import { ActionContext } from 'vuex';

import $http from '@/plugins/axios';

import { IRootState } from '@/interfaces/RootState';
import {
  ICustomerRequest,
  ICustomerResponse,
  ICustomerState,
} from '@/interfaces/Customer';

export default {
  namespaced: true,

  state: {
    customerResponse: {
      customer_no: '',
      external_id: '',
      customer_name: '',
      customer_sort_key: '',
      salesperson_id_1: '',
      salesperson_name_1: '',
      collector_name: '',
      address_line_1: '',
      address_line_2: '',
      city: '',
      state: '',
      formatted_postal_code: '',
      country_code: '',
      formatted_phone_no: '',
      credit_limit: '',
      date_added: new Date(),
    },
  },

  getters: {
    getCustomerResponse: (state: ICustomerState): ICustomerResponse => state.customerResponse,
  },

  mutations: {
    SET_CUSTOMER_RESPONSE(state: ICustomerState, payload: ICustomerResponse): void {
      state.customerResponse = payload;
    },
  },

  actions: {
    async fetchCustomer(
      { commit }: ActionContext<ICustomerState, IRootState>,
      payload: { viewName: string; gridName: string; gridRequest: ICustomerRequest; },
    ): Promise<void> {
      try {
        const response = await $http.Api({
          method: 'POST',
          url: `/viewdata/grid/${payload.viewName}/${payload.gridName}`,
          data: payload.gridRequest,
        });
        console.log(response);
        if (response.data && response.data.data && response.data.data.rows) {
          commit('SET_CUSTOMER_RESPONSE', response.data.data.rows[0]);
        }
      } catch (error) {
        throw error.response;
      }
    },
  },
};
