import { computed, ComputedRef } from 'vue';
import { useStore } from 'vuex';
import { LocationQueryValue } from 'vue-router';

import {
  IAction,
  IColumn, IComponentResponse, IViewResponse, IViewsResponse,
} from '@/interfaces/View';
import { IFilter } from '@/interfaces/Filter';
import { IDrillThroughRequest } from '@/interfaces/DrillThrough';
import { IOrderRequest } from '@/interfaces/Order';

export default function useView(): {
  views: ComputedRef<IViewsResponse[]>;
  view: ComputedRef<IViewResponse>;
  charts: ComputedRef<IComponentResponse>;
  filters: ComputedRef<IFilter[]>;
  drillThrough: ComputedRef<string | undefined>;
  hasNotes: ComputedRef<boolean>;
  gridFilters: ComputedRef<IFilter[]>;
  gridOrders: ComputedRef<IOrderRequest[]>;
  datasetColumns: ComputedRef<IColumn[]>;
  datasetMetrics: ComputedRef<IColumn[]>;
  columns: ComputedRef<IColumn[]>;
  hiddenColumns: ComputedRef<IColumn[]>;
  dimensions: ComputedRef<IColumn[]>;
  metrics: ComputedRef<IColumn[]>;
  dataSource: ComputedRef<string>;
  gridName: ComputedRef<string>;
  actions: ComputedRef<IAction[]>;
  userSelection: ComputedRef<string>;
  drilldown: (payload: string) => IComponentResponse;
  fetchViews: () => Promise<void>;
  fetchView: (payload: string) => Promise<void>;
  fetchDrillThrough(payload: IDrillThroughRequest): Promise<void>;
  fetchFavorite: (payload: {
    viewName: string;
    favoriteName: string;
  }) => Promise<void>;
  resetView: (payload: string) => Promise<void>;
  addColumn: (payload: IColumn) => void;
  updateColumn: (payload: {
    index: number;
    column: IColumn;
  }) => void;
  updateColumns: (payload: IColumn[]) => void;
  removeColumn: (payload: string) => void;
  updateFilters: (payload: { viewName: string; filters: IFilter[] }) => Promise<void>;
  setGridFilters(payload: IFilter[]): void;
  addGridFilter(payload: IFilter): void;
  removeGridFilter(payload: IFilter): void;
  addMetric: (payload: IColumn) => void;
  removeGridColumnFilters: (payload: string) => void;
  removeGridFilters: () => void;
  } {
  const store = useStore();

  const views = computed((): IViewsResponse[] => store.getters['View/getViews']);

  const view = computed((): IViewResponse => store.getters['View/getView']);

  const charts = computed((): IComponentResponse => store.getters['View/getCharts']);

  const filters = computed((): IFilter[] => store.getters['View/getFilters']);

  const drillThrough = computed(
    (): string | undefined => store.getters['View/getGridDrillThrough'],
  );

  const hasNotes = computed((): boolean => store.getters['View/getGridNotesStatus']);

  const gridFilters = computed((): IFilter[] => store.getters['View/getGridFilters']);

  const gridOrders = computed((): IOrderRequest[] => store.getters['View/getGridOrders']);

  const datasetColumns = computed((): IColumn[] => store.getters['View/getDatasetColumns']);

  const datasetMetrics = computed((): IColumn[] => store.getters['View/getDatasetMetrics']);

  const columns = computed((): IColumn[] => store.getters['View/getColumns']);

  const hiddenColumns = computed((): IColumn[] => store.getters['View/getHiddenColumns']);

  const dimensions = computed((): IColumn[] => store.getters['View/getDimensions']);

  const metrics = computed((): IColumn[] => store.getters['View/getMetrics']);

  const dataSource = computed((): string => store.getters['View/getDataSource']);

  const gridName = computed((): string => store.getters['View/getGridName']);

  const actions = computed((): IAction[] => store.getters['View/getActions']);

  const userSelection = computed((): string => store.getters['View/getUserSelection']);

  function drilldown(payload: string | LocationQueryValue[]): IComponentResponse {
    return store.getters['View/getDrilldowns'].find((el: IComponentResponse) => el.name === payload);
  }

  function addColumn(payload: IColumn): void {
    store.commit('View/ADD_COLUMN', payload);
  }

  function addMetric(payload: IColumn): void {
    store.commit('View/ADD_METRIC', payload);
  }

  function updateColumn(payload: { index: number; column: IColumn }): void {
    store.commit('View/UPDATE_COLUMN', payload);
  }

  function updateColumns(payload: IColumn[]): void {
    store.commit('View/UPDATE_COLUMNS', payload);
  }

  function removeColumn(payload: string): void {
    store.commit('View/REMOVE_COLUMN', payload);
  }

  function setGridFilters(payload: IFilter[]): void {
    store.commit('View/SET_GRID_FILTERS', payload);
  }

  function addGridFilter(payload: IFilter): void {
    store.commit('View/ADD_GRID_FILTER', payload);
  }

  function removeGridFilter(payload: IFilter): void {
    store.commit('View/REMOVE_GRID_FILTER', payload);
  }

  function removeGridColumnFilters(payload: string): void {
    store.commit('View/REMOVE_GRID_COLUMN_FILTERS', payload);
  }

  function removeGridFilters(): void {
    store.commit('View/REMOVE_GRID_FILTERS');
  }

  async function fetchViews(): Promise<void> {
    try {
      await store.dispatch('View/fetchViews');
    } catch (error) {
      console.error(error);
    }
  }

  async function fetchDrillThrough(payload: IDrillThroughRequest): Promise<void> {
    try {
      await store.dispatch('View/fetchDrillThrough', payload);
    } catch (error) {
      console.error(error);
    }
  }

  async function fetchView(payload: string): Promise<void> {
    try {
      await store.dispatch('View/fetchView', payload);
    } catch (error) {
      console.error(error);
    }
  }

  async function fetchFavorite(payload: { viewName: string; favoriteName: string }): Promise<void> {
    try {
      await store.dispatch('View/fetchFavorite', payload);
    } catch (error) {
      console.error(error);
    }
  }

  async function updateFilters(
    payload: { viewName: string; filters: IFilter[] },
  ): Promise<void> {
    try {
      await store.dispatch('View/updateFilters', payload);
    } catch (error) {
      console.error(error);
    }
  }

  async function resetView(payload: string): Promise<void> {
    try {
      await store.dispatch('View/resetView', payload);
    } catch (error) {
      console.error(error);
    }
  }

  return {
    views,
    view,
    charts,
    filters,
    drillThrough,
    hasNotes,
    gridFilters,
    gridOrders,
    datasetColumns,
    datasetMetrics,
    columns,
    hiddenColumns,
    dimensions,
    metrics,
    dataSource,
    gridName,
    actions,
    userSelection,
    drilldown,
    fetchViews,
    fetchDrillThrough,
    fetchView,
    fetchFavorite,
    resetView,
    addColumn,
    updateColumn,
    updateColumns,
    removeColumn,
    updateFilters,
    setGridFilters,
    addGridFilter,
    removeGridFilter,
    addMetric,
    removeGridColumnFilters,
    removeGridFilters,
  };
}
