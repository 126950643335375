import { ActionContext } from 'vuex';

import $http from '@/plugins/axios';

import {
  IExportRequest,
  IExportResponse,
  IExportState,
} from '@/interfaces/Export';
import { IRootState } from '@/interfaces/RootState';
import { IFilter } from '@/interfaces/Filter';
import { IOrderRequest } from '@/interfaces/Order';

export default {
  namespaced: true,

  state: {
    exportRequest: {
      page_size: 100,
      page_index: 0,
      orders: [],
      filters: [],
    },

    exportResponse: {
      success: false,
      dataset_name: '',
      fields: [],
      rows: [],
      totals: null,
      pagination: {
        page_size: 100,
        page_index: 0,
        page_count: 0,
        total_rows: 0,
      },
    },
  },

  getters: {
    getExportResponse: (state: IExportState): IExportResponse => state.exportResponse,
    getExportRequest: (state: IExportState): IExportRequest => state.exportRequest,
  },

  mutations: {
    SET_EXPORT_REQUEST(state: IExportState, payload: IExportRequest): void {
      state.exportRequest = payload;
    },

    RESET_EXPORT_REQUEST(state: IExportState): void {
      state.exportRequest = {
        page_size: 100,
        page_index: 0,
        orders: [],
        filters: [],
      };
    },

    SET_EXPORT_REQUEST_FILTERS(state: IExportState, payload: IFilter[]): void {
      state.exportRequest.filters = payload;
    },

    SET_EXPORT_REQUEST_ORDERS(state: IExportState, payload: IOrderRequest[]): void {
      state.exportRequest.orders = payload;
    },

    SET_EXPORT_REQUEST_PAGE_SIZE(state: IExportState, payload: number): void {
      state.exportRequest.page_size = payload;
    },

    SET_EXPORT_RESPONSE(state: IExportState, payload: IExportResponse): void {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const data = payload.rows.map((el, index) => ({ ...el, id: index }));
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      // eslint-disable-next-line no-param-reassign
      payload.rows = data;
      state.exportResponse = payload;
    },
  },

  actions: {
    /**
     *
     *
     * @param {ActionContext<IExportState, IRootState>} { commit }
     * @param {{ viewName: string; gridName: string; exportRequest: IExportRequest; }} payload
     * @return {*}  {Promise<void>}
     */
    async fetchExportData(
      { commit }: ActionContext<IExportState, IRootState>,
      payload: { viewName: string; gridName: string; exportRequest: IExportRequest; },
    ): Promise<void> {
      try {
        const response = await $http.Api({
          method: 'POST',
          url: `/viewdata/grid/${payload.viewName}/${payload.gridName}`,
          data: payload.exportRequest,
        });
        commit('SET_EXPORT_RESPONSE', response.data?.data);
      } catch (error) {
        throw error.response;
      }
    },
  },
};
