import { ActionContext } from 'vuex';

import $http from '@/plugins/axios';

import { IRootState } from '@/interfaces/RootState';
import {
  IColumnRequest, IColumnResponse, IColumnState,
} from '@/interfaces/Column';

export default {
  namespaced: true,

  state: {
    columnResponse: {
      success: false,
      dataset_name: '',
      fields: [],
      rows: [],
      pagination: {
        page_count: 0,
        page_index: 0,
        page_size: 0,
        total_rows: 0,
      },
    },
  },

  getters: {
    getColumnResponse: (state: IColumnState): IColumnResponse => state.columnResponse,
  },

  mutations: {
    SET_COLUMN_RESPONSE(state: IColumnState, payload: IColumnResponse): void {
      state.columnResponse = payload;
    },

    RESET_COLUMN_RESPONSE(state: IColumnState): void {
      state.columnResponse = {
        success: false,
        dataset_name: '',
        fields: [],
        rows: [],
        pagination: {
          page_count: 0,
          page_index: 0,
          page_size: 0,
          total_rows: 0,
        },
      };
    },
  },

  actions: {
    /**
     *
     *
     * @param {ActionContext<IDatasetState, IRootState>} { commit }
     * @param {IDatasetRequest} payload
     * @return {*}  {Promise<void>}
     */
    async fetchColumnData(
      { commit }: ActionContext<IColumnState, IRootState>,
      payload: IColumnRequest,
    ): Promise<void> {
      try {
        const response = await $http.Api({
          method: 'POST',
          url: '/dataset/data',
          data: payload,
        });

        commit('SET_COLUMN_RESPONSE', response.data);
      } catch (error) {
        throw error.response;
      }
    },
  },
};
